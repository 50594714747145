import styled from 'styled-components';
import tw from 'twin.macro';

export const Avatar = styled.img<{ link?: string }>`
  border-radius: 50%;
  height: 54px;
  width: 54px;

  ${({ link }) => link && tw`cursor-pointer`}
`;
