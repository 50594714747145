import React from 'react';
import { RichTextRender } from 'src/cms';
import { BountyType } from 'src/cms/interfaces/bounty';
import { ContentSection } from 'src/components/layout/layout';
import 'twin.macro';
import BipCard from '../lib/bipCard';
import { H4 } from '../lib/typography';
import { ClaimedBounties } from './claimedBounties';
import { Rules } from './rules';
import { ContentBox, SupportersTitle } from './style';
import { Supporters } from './supporters';
import { Rewards } from './rewards';

export const Detail = ({ bounty }: { bounty: BountyType }) => {
  return (
    <ContentSection>
      <BipCard lg id={bounty.id} title={bounty.title} author={bounty.author} />
      <div className="grid grid-cols-1 lg:grid-cols-12">
        <div className="lg:col-start-1 lg:col-end-9">
          <ContentBox>
            <H4>Description</H4>
            <RichTextRender data={bounty.description} />
          </ContentBox>
          <ContentBox>
            <H4>Motivation</H4>
            <RichTextRender data={bounty.motivation} />
          </ContentBox>
          <ContentBox>
            <H4>Resources</H4>
            <RichTextRender data={bounty.access} />
          </ContentBox>
          <ContentBox>
            <H4>Types of Rewards</H4>
            <Rewards />
          </ContentBox>
          <ContentBox>
            <H4>How to help:</H4>
            <Rules id={bounty.id} />
          </ContentBox>
          {/* Commented out per Brandon on 2022-06-20
          <ContentBox>
            <H4>Claimed Bounties</H4>
            <ClaimedBounties claims={bounty.claimedBounties} />
          </ContentBox> */}
        </div>
        <div className="lg:col-start-10 lg:col-end-13">
          <ContentBox>
            <SupportersTitle>Supporters of this Bounty</SupportersTitle>
            <Supporters supporters={bounty.supporters} />
          </ContentBox>
        </div>
      </div>
    </ContentSection>
  );
};
