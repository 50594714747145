import * as React from 'react';
import { graphql } from 'gatsby';
import { Detail } from 'src/components/detail';
import { Layout } from 'src/components/layout/layout';

function BountyDetail(props) {
  const { contentfulBounties } = props.data;
  return (
    <Layout>
      <Detail bounty={contentfulBounties} />
    </Layout>
  );
}

export default BountyDetail;

export const query = graphql`
  query ($id: String!) {
    contentfulBounties(id: { eq: $id }) {
      id
      title
      author
      access {
        raw
      }
      description {
        raw
      }
      motivation {
        raw
      }
      claimedBounties {
        amount
        description {
          description
        }
        person {
          name
          link
          avatar {
            url
          }
        }
      }
      supporters {
        name
        link
        avatar {
          url
        }
      }
    }
  }
`;
