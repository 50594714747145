import React from 'react';
import { Paragraph } from 'src/components/lib/typography';
import 'twin.macro';
import { RuleBox, RuleNumber, StyledLink } from './style';

export const Rules = ({ id }: { id: string }) => {
  const rules = [
    <StyledLink to="/donate">Donate</StyledLink>,
    'Share with the community',
    <StyledLink to={`/bounties/submit-report/${id}`}>Submit a report</StyledLink>,
    <StyledLink to="/submit-bip">Submit a new BIP</StyledLink>,
  ];
  return (
    <div className="pt-6">
      {rules.map((rule, index) => (
        <RuleBox key={index} index={index}>
          <RuleNumber>{index + 1}</RuleNumber>
          <div>
            <Paragraph>{rule}</Paragraph>
          </div>
        </RuleBox>
      ))}
    </div>
  );
};
