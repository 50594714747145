import React from 'react';
import { BountyType } from 'src/cms/interfaces/bounty';
import { Avatar } from 'src/components/lib/avatar';
import 'twin.macro';

export const Supporters = ({ supporters }: { supporters: BountyType['supporters'] }) => {
  if (!supporters) return null;
  return (
    <div className="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-3 pt-6 gap-y-4">
      {supporters.map((person, index) => (
        <div key={index} className="flex justify-center">
          <Avatar
            link={person.link}
            onClick={() => person.link && window.open(person.link, '_blank')}
            src={person.avatar.url}
          />
        </div>
      ))}
    </div>
  );
};
