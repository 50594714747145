import React from 'react';
import { RichTextRender, useGetAllRewards } from 'src/cms';
import { H5 } from 'src/components/lib/typography';
import 'twin.macro';
import { Tag } from './style';

export const Rewards = () => {
  const rewardsList = useGetAllRewards();

  return (
    <div className="pt-4">
      {rewardsList.map((reward, index) => (
        <div className="my-4" key={index}>
          <div className="lg:flex mb-2 justify-between">
            <H5>{reward.name}</H5>
            <Tag className="mt-2">{reward.tag}</Tag>
          </div>
          <RichTextRender data={reward.description} />
        </div>
      ))}
    </div>
  );
};
