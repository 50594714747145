import { Link } from 'gatsby';
import styled from 'styled-components';
import tw from 'twin.macro';
export const RuleNumber = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  ${tw`
    pr-8
    lg:pr-12
  `}
`;

export const RuleBox = styled.div<{ index: number }>`
  ${tw`
    flex my-4
  `}
  ${({ index }) => index && tw`border-top-width[1px] pt-4`}
`;

export const StyledLink = styled(Link)`
  ${tw`
    hover:underline
    cursor-pointer
  `}
  color: rgb(0, 0, 238);
  &:visited {
    color: rgb(85, 26, 139);
  }
`;
