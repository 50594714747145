import styled from 'styled-components';
import tw from 'twin.macro';

export const Tag = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* or 18px */

  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: #555555;
`;
