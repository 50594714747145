import styled from 'styled-components';
import tw from 'twin.macro';

export const ContentBox = styled.div`
  border: 1px solid #dbdee7;
  border-radius: 25px;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }

  ${tw`
    my-5
    lg:my-8
  `}
`;

export const SupportersTitle = styled.div`
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
`;
